import React from "react";

const AppleButton = () => {
  return (
    <a
      style={{ padding: "5px" }}
      href="https://apps.apple.com/app/sunn/id1630493103"
    >
      <svg
        height={70}
        viewBox="0 0 168 47.931"
        // width="168"
        // height="47.931"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m168 43.127a4.819 4.819 0 0 1 -4.838 4.8h-158.318a4.825 4.825 0 0 1 -4.844-4.8v-38.316a4.831 4.831 0 0 1 4.844-4.811h158.317a4.824 4.824 0 0 1 4.839 4.811z"
          fill="#121314"
        />
        <g fill="#fff">
          <path
            d="m21.831 9.253a7.231 7.231 0 0 1 3.477-6.071 7.5 7.5 0 0 0 -5.889-3.156c-2.477-.258-4.879 1.474-6.141 1.474-1.287 0-3.23-1.444-5.324-1.4a7.849 7.849 0 0 0 -6.6 3.989c-2.854 4.891-.724 12.087 2.01 16.045 1.368 1.938 2.966 4.1 5.057 4.027 2.046-.084 2.811-1.293 5.28-1.293 2.447 0 3.165 1.293 5.3 1.244 2.2-.035 3.579-1.947 4.9-3.9a15.954 15.954 0 0 0 2.24-4.522 7 7 0 0 1 -4.307-6.433z"
            transform="translate(15.108 14.443)"
          />
          <path
            d="m4.812 5.1a7.081 7.081 0 0 0 1.645-5.1 7.341 7.341 0 0 0 -4.732 2.427 6.741 6.741 0 0 0 -1.688 4.915 6.066 6.066 0 0 0 4.775-2.242z"
            transform="translate(28.097 6.762)"
          />
          <path
            d="m2.784 16.612h-2.784l5.395-16.612h3.331l5.421 16.61h-2.86l-1.565-4.878h-5.445l-1.492 4.879zm4.153-14.394c-.165.706-.425 1.73-.8 3.128l-1.387 4.339h4.5l-1.417-4.338c-.148-.436-.424-1.459-.845-3.128z"
            transform="translate(52.405 21.714)"
          />
          <path
            d="m2.785 17.1h-2.685v-12.911c0-1.256-.034-2.583-.1-3.943h2.361l.15 1.9h.05a4.5 4.5 0 0 1 4.077-2.146 4.514 4.514 0 0 1 3.568 1.675 6.592 6.592 0 0 1 1.431 4.413 6.8 6.8 0 0 1 -1.666 4.829 4.915 4.915 0 0 1 -3.705 1.578 3.728 3.728 0 0 1 -3.431-1.7h-.05zm2.984-14.981a2.819 2.819 0 0 0 -1.8.652 3.043 3.043 0 0 0 -1.059 1.714 3.49 3.49 0 0 0 -.124.812v2a3.2 3.2 0 0 0 .808 2.203 2.7 2.7 0 0 0 2.1.9 2.769 2.769 0 0 0 2.362-1.158 5.1 5.1 0 0 0 .844-3.057 4.927 4.927 0 0 0 -.8-2.884 2.77 2.77 0 0 0 -2.331-1.182z"
            transform="translate(68.79 26.101)"
          />
          <path
            d="m2.785 17.1h-2.685v-12.911c0-1.256-.034-2.583-.1-3.943h2.361l.15 1.9h.05a4.5 4.5 0 0 1 4.077-2.146 4.526 4.526 0 0 1 3.569 1.675 6.611 6.611 0 0 1 1.427 4.413 6.8 6.8 0 0 1 -1.667 4.829 4.908 4.908 0 0 1 -3.7 1.578 3.725 3.725 0 0 1 -3.43-1.7h-.05v6.305zm2.982-14.981a2.819 2.819 0 0 0 -1.8.652 3.033 3.033 0 0 0 -1.058 1.714 3.494 3.494 0 0 0 -.124.812v2a3.207 3.207 0 0 0 .805 2.203 2.711 2.711 0 0 0 2.1.9 2.766 2.766 0 0 0 2.364-1.158 5.091 5.091 0 0 0 .846-3.057 4.927 4.927 0 0 0 -.8-2.885 2.767 2.767 0 0 0 -2.333-1.181z"
            transform="translate(82.689 26.101)"
          />
          <path
            d="m10.4 12.2a4.409 4.409 0 0 1 -1.488 3.45 6.635 6.635 0 0 1 -4.569 1.45 8.046 8.046 0 0 1 -4.343-1.034l.622-2.218a7.409 7.409 0 0 0 3.878 1.061 3.692 3.692 0 0 0 2.364-.679 2.21 2.21 0 0 0 .844-1.815 2.3 2.3 0 0 0 -.7-1.7 6.488 6.488 0 0 0 -2.308-1.287q-4.4-1.628-4.4-4.763a4.2 4.2 0 0 1 1.545-3.357 6.116 6.116 0 0 1 4.102-1.308 8.194 8.194 0 0 1 3.8.789l-.668 2.169a6.645 6.645 0 0 0 -3.207-.764 3.265 3.265 0 0 0 -2.211.69 1.965 1.965 0 0 0 -.671 1.5 2.023 2.023 0 0 0 .769 1.626 8.72 8.72 0 0 0 2.441 1.29 8.279 8.279 0 0 1 3.182 2.02 4.2 4.2 0 0 1 1.018 2.88z"
            transform="translate(99.468 21.47)"
          />
          <path
            d="m7.359 4.982h-2.959v5.818q0 2.219 1.567 2.218a4.815 4.815 0 0 0 1.193-.124l.074 2.021a6.132 6.132 0 0 1 -2.088.295 3.236 3.236 0 0 1 -2.487-.961 4.692 4.692 0 0 1 -.9-3.229v-6.041h-1.759v-2h1.763v-2.19l2.637-.789v2.982h2.959z"
            transform="translate(111.381 23.363)"
          />
          <path
            d="m5.817 12.494a5.448 5.448 0 0 1 -4.237-1.75 6.359 6.359 0 0 1 -1.58-4.411 6.5 6.5 0 0 1 1.628-4.558 5.683 5.683 0 0 1 4.388-1.775 5.565 5.565 0 0 1 4.276 1.75 6.291 6.291 0 0 1 1.544 4.386 6.562 6.562 0 0 1 -1.592 4.534 5.731 5.731 0 0 1 -4.427 1.824zm.1-10.548a2.709 2.709 0 0 0 -2.459 1.433 5.488 5.488 0 0 0 -.72 2.893 5.365 5.365 0 0 0 .72 2.84 2.755 2.755 0 0 0 4.848-.024 5.391 5.391 0 0 0 .744-2.867 5.384 5.384 0 0 0 -.72-2.842 2.667 2.667 0 0 0 -2.41-1.433z"
            transform="translate(120.257 26.102)"
          />
          <path
            d="m6.465 2.586a4.7 4.7 0 0 0 -.846-.074 2.544 2.544 0 0 0 -2.189 1.062 3.962 3.962 0 0 0 -.671 2.365v6.285h-2.683l.024-8.206q0-2.071-.1-3.771h2.338l.1 2.292h.074a4.1 4.1 0 0 1 1.341-1.897 3.265 3.265 0 0 1 1.941-.642 4.348 4.348 0 0 1 .671.049z"
            transform="translate(134.354 26.101)"
          />
          <path
            d="m6.018 12.469a5.925 5.925 0 0 1 -4.418-1.639 6.051 6.051 0 0 1 -1.6-4.399 6.856 6.856 0 0 1 1.493-4.51 5.186 5.186 0 0 1 4.226-1.921 4.532 4.532 0 0 1 3.954 1.921 6.463 6.463 0 0 1 1.066 3.77 6.158 6.158 0 0 1 -.1 1.207h-8.052a3.477 3.477 0 0 0 1.168 2.712 4.034 4.034 0 0 0 2.634.837 9.017 9.017 0 0 0 3.259-.566l.421 1.847a10.193 10.193 0 0 1 -4.051.741zm-.5-10.624a2.564 2.564 0 0 0 -2.137 1.085 3.964 3.964 0 0 0 -.794 2.07h5.592a3.6 3.6 0 0 0 -.522-2.045 2.358 2.358 0 0 0 -2.139-1.11z"
            transform="translate(142.087 26.079)"
          />
          <path
            d="m1.931 8.294a16.5 16.5 0 0 1 -1.931-.094v-8.03a14.426 14.426 0 0 1 2.273-.17 4.949 4.949 0 0 1 3.261.932 3.767 3.767 0 0 1 1.233 3.016 4.1 4.1 0 0 1 -1.332 3.318 5.353 5.353 0 0 1 -3.504 1.028zm.451-7.264a5.648 5.648 0 0 0 -1.063.086v6.1a6.915 6.915 0 0 0 .892.036 3.149 3.149 0 0 0 2.338-.835 3.337 3.337 0 0 0 .826-2.434 2.979 2.979 0 0 0 -.763-2.192 3.035 3.035 0 0 0 -2.23-.761z"
            transform="translate(53.999 7.547)"
          />
          <path
            d="m2.858 6.137a2.68 2.68 0 0 1 -2.084-.859 3.132 3.132 0 0 1 -.774-2.168 3.193 3.193 0 0 1 .8-2.239 2.8 2.8 0 0 1 2.156-.871 2.74 2.74 0 0 1 2.1.859 3.1 3.1 0 0 1 .757 2.155 3.23 3.23 0 0 1 -.782 2.228 2.816 2.816 0 0 1 -2.173.895zm.05-5.182a1.333 1.333 0 0 0 -1.211.7 2.7 2.7 0 0 0 -.354 1.42 2.651 2.651 0 0 0 .354 1.4 1.341 1.341 0 0 0 1.2.7 1.323 1.323 0 0 0 1.184-.716 2.644 2.644 0 0 0 .366-1.409 2.655 2.655 0 0 0 -.354-1.4 1.311 1.311 0 0 0 -1.185-.695z"
            transform="translate(62.331 9.765)"
          />
          <path
            d="m8.6 0-1.857 5.884h-1.209l-.769-2.555a18.985 18.985 0 0 1 -.477-1.9h-.024a13.817 13.817 0 0 1 -.477 1.9l-.818 2.555h-1.222l-1.747-5.884h1.356l.671 2.8q.244.992.4 1.889h.024q.147-.738.49-1.876l.846-2.813h1.075l.807 2.75q.293 1.005.476 1.937h.037a18.367 18.367 0 0 1 .4-1.937l.718-2.75z"
            transform="translate(69.437 9.885)"
          />
          <path
            d="m5.2 6.008h-1.316v-3.371q0-1.558-1.2-1.558a1.2 1.2 0 0 0 -.953.428 1.511 1.511 0 0 0 -.366 1.009v3.49h-1.317v-4.206q0-.77-.048-1.679h1.16l.062.92h.037a1.9 1.9 0 0 1 .684-.71 2.245 2.245 0 0 1 1.195-.331 1.9 1.9 0 0 1 1.381.533 2.576 2.576 0 0 1 .681 1.95z"
            transform="translate(79.676 9.761)"
          />
          <path
            d="m1.318 8.584h-1.318v-8.584h1.318z"
            transform="translate(87.2 7.185)"
          />
          <path
            d="m2.858 6.137a2.675 2.675 0 0 1 -2.084-.859 3.132 3.132 0 0 1 -.774-2.168 3.193 3.193 0 0 1 .8-2.239 2.8 2.8 0 0 1 2.154-.871 2.745 2.745 0 0 1 2.1.859 3.1 3.1 0 0 1 .757 2.155 3.23 3.23 0 0 1 -.782 2.228 2.816 2.816 0 0 1 -2.171.895zm.05-5.182a1.331 1.331 0 0 0 -1.211.7 2.7 2.7 0 0 0 -.354 1.42 2.651 2.651 0 0 0 .354 1.4 1.341 1.341 0 0 0 1.2.7 1.324 1.324 0 0 0 1.183-.716 2.637 2.637 0 0 0 .366-1.409 2.663 2.663 0 0 0 -.354-1.4 1.309 1.309 0 0 0 -1.184-.695z"
            transform="translate(90.474 9.765)"
          />
          <path
            d="m1.82 6.137a1.8 1.8 0 0 1 -1.356-.537 1.669 1.669 0 0 1 -.464-1.194 1.835 1.835 0 0 1 .911-1.647 4.706 4.706 0 0 1 2.484-.559h.075v-.11c0-.771-.415-1.162-1.233-1.162a2.786 2.786 0 0 0 -1.547.439l-.268-.859a3.849 3.849 0 0 1 2.036-.508c1.542 0 2.33.819 2.33 2.434v2.166a9.153 9.153 0 0 0 .085 1.4h-1.185l-.1-.678h-.035a2.046 2.046 0 0 1 -1.733.815zm1.555-3.064a3.171 3.171 0 0 0 -1.542.3.969.969 0 0 0 -.514.89.884.884 0 0 0 .254.689.925.925 0 0 0 .644.23 1.343 1.343 0 0 0 .807-.273 1.121 1.121 0 0 0 .457-.7 1.107 1.107 0 0 0 .022-.23v-.905z"
            transform="translate(97.801 9.765)"
          />
          <path
            d="m2.491 8.717a2.25 2.25 0 0 1 -1.784-.835 3.249 3.249 0 0 1 -.707-2.166 3.4 3.4 0 0 1 .769-2.316 2.386 2.386 0 0 1 1.831-.821 1.792 1.792 0 0 1 1.672.8h.028v-3.379h1.321v7c0 .592.015 1.111.046 1.586h-1.167l-.061-.945h-.039a1.983 1.983 0 0 1 -1.909 1.076zm.409-5.117a1.367 1.367 0 0 0 -1.161.581 2.488 2.488 0 0 0 -.423 1.49 2.326 2.326 0 0 0 .405 1.417 1.368 1.368 0 0 0 1.154.581 1.284 1.284 0 0 0 1.045-.49 1.611 1.611 0 0 0 .38-1.079v-.979a1.88 1.88 0 0 0 -.037-.411 1.487 1.487 0 0 0 -.477-.793 1.308 1.308 0 0 0 -.886-.317z"
            transform="translate(104.507 7.185)"
          />
          <path
            d="m2.858 6.137a2.682 2.682 0 0 1 -2.084-.859 3.132 3.132 0 0 1 -.774-2.168 3.193 3.193 0 0 1 .8-2.239 2.8 2.8 0 0 1 2.157-.871 2.737 2.737 0 0 1 2.1.859 3.1 3.1 0 0 1 .757 2.155 3.23 3.23 0 0 1 -.782 2.228 2.816 2.816 0 0 1 -2.174.895zm.05-5.182a1.334 1.334 0 0 0 -1.211.7 2.7 2.7 0 0 0 -.354 1.42 2.651 2.651 0 0 0 .354 1.4 1.341 1.341 0 0 0 1.2.7 1.323 1.323 0 0 0 1.183-.711 2.651 2.651 0 0 0 .366-1.409 2.655 2.655 0 0 0 -.354-1.4 1.314 1.314 0 0 0 -1.184-.7z"
            transform="translate(115.646 9.765)"
          />
          <path
            d="m5.2 6.008h-1.316v-3.371q0-1.558-1.2-1.558a1.191 1.191 0 0 0 -.952.428 1.5 1.5 0 0 0 -.366 1.009v3.49h-1.319v-4.206q0-.77-.047-1.679h1.159l.062.92h.037a1.917 1.917 0 0 1 .684-.71 2.25 2.25 0 0 1 1.196-.331 1.9 1.9 0 0 1 1.38.533 2.571 2.571 0 0 1 .682 1.95z"
            transform="translate(123.354 9.761)"
          />
          <path
            d="m3.615 2.445h-1.453v2.855c0 .726.258 1.09.769 1.09a2.344 2.344 0 0 0 .588-.061l.034.992a2.989 2.989 0 0 1 -1.025.146 1.585 1.585 0 0 1 -1.22-.467 2.293 2.293 0 0 1 -.44-1.588v-2.967h-.868v-.98h.868v-1.078l1.293-.387v1.464h1.453v.981z"
            transform="translate(133.822 8.419)"
          />
          <path
            d="m5.155 8.584h-1.321v-3.345q0-1.582-1.2-1.583a1.259 1.259 0 0 0 -1.259.917 1.63 1.63 0 0 0 -.062.471v3.539h-1.313v-8.583h1.318v3.546h.025a2 2 0 0 1 1.783-.967 1.848 1.848 0 0 1 1.357.533 2.665 2.665 0 0 1 .671 1.973v3.5z"
            transform="translate(139.269 7.185)"
          />
          <path
            d="m2.954 6.125a2.9 2.9 0 0 1 -2.167-.805 2.967 2.967 0 0 1 -.787-2.161 3.366 3.366 0 0 1 .733-2.216 2.546 2.546 0 0 1 2.075-.943 2.223 2.223 0 0 1 1.94.943 3.137 3.137 0 0 1 .529 1.851 3.139 3.139 0 0 1 -.049.593h-3.957a1.717 1.717 0 0 0 .572 1.333 1.99 1.99 0 0 0 1.3.412 4.424 4.424 0 0 0 1.6-.279l.206.909a5.035 5.035 0 0 1 -1.995.363zm-.241-5.219a1.261 1.261 0 0 0 -1.05.533 1.974 1.974 0 0 0 -.391 1.017h2.747a1.76 1.76 0 0 0 -.256-1 1.161 1.161 0 0 0 -1.05-.55z"
            transform="translate(146.353 9.754)"
          />
        </g>
      </svg>
    </a>
  );
};

export default AppleButton;
